<template>
 <v-container fluid class="py-3 px-5">
   <v-row>
     <v-col cols="12" class="pb-1">
       <p class="ma-1">
        Locations
       </p>
     </v-col>
   </v-row>
   <v-row>
     <v-col cols="12" class="pt-1">
       <LocationTable :headers="headers" :boards="boards">

       </LocationTable>
     </v-col>
   </v-row>
 </v-container>
</template>

<script>
import LocationTable from '@/components/LocationTable.vue' 
export default {
  name: 'Locations',
  components: {
    LocationTable
  },
  data() {
    return {
      headers: {
        location: [
          {
            text: 'Location',
            align: 'start',
            value: 'location',
          },
          {
            text: 'Info_Location',
            align: 'start',
            value: 'info_location',
          },
          {
            text: 'Aditional_info',
            align: 'start',
            value: 'adicional_info',
          },
          {
            text: 'Creado',
            align: 'center',
            value: 'createdAt',
          },
          {
            text: 'Acciones',
            align: 'center',
            value: 'actions',
          },
          
        ],
      },
      boards: {
        location: 'location',
      }
    }
  },
}
</script>

<style>

</style>