<template>
  <div>
    <v-row align="start">
      <v-col sm="12" md="12">
        <v-card class="rounded-xl">
          <v-card-title class="py-2">
            <v-row align="center" justify="space-around">
              <v-col cols="12" sm="12" md="3" >
                <h5>Locations</h5>
              </v-col>
              <v-col cols="12" sm="12" md="5" >
                <v-text-field
                  class="pt-0"
                  v-model="locationSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" class="d-flex justify-center">
                <v-btn
                  dense
                  small
                  color="primary"
                  @click="openCreateForm(boards.location)"
                >
                  Crear Location
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="pa-0 ma-0">
            <v-data-table
              dense
              :headers="headers.location"
              :items="location_records"
              :items-per-page="20"
              multi-sort
              item-key="id"
              class=" rounded-xl"
              :search="locationSearch"
              :loading="loadingLocationTable"
              loading-text="Loading... Please wait"
              sort-by="createdAt"
              sort-desc
            >

              <template v-slot:item.actions="{ item }">
                <v-icon color="warning" small class="mr-2" @click="editItem(item, boards.location)">
                  mdi-pencil
                </v-icon>
                <v-icon color="primary-red" small @click="openDelete(item, boards.location)">
                  mdi-delete
                </v-icon>
              </template>

            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>



    <!-- Location Form -->
    <v-row justify="center" >
      <v-form ref="createLocationForm" v-model="valid" lazy-validation >
        <v-dialog  v-model="dialogLocation" persistent max-width="750px">
          <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl" >
            <v-card-title>
              <span class="text-h6">{{ btnEdithVisible ? `Edicion Location ${createLocationForm.name}` : `Crear Location` }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-container>
                <v-row dense>
                  <v-col cols="12" md="12">
                    <v-text-field
                      dense
                      label="Location"
                      v-model="createLocationForm.location"
                      :rules="[createFormRules.required]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      label="Info Location"
                      v-model="createLocationForm.info_location"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      label="Aditional Info"
                      v-model="createLocationForm.adicional_info"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                :disable="isLoading"
                color="blue darken-1" text @click="closeForm(boards.location)">
                Cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="!btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('POST', boards.location)"
              >
                Guardar y cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('PUT', boards.location)"
              >
                Guardar Edicion
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>



    <!-- DIALOG DELETE PRODUCT -->
    <v-dialog v-model="dialogDeletepLocation" max-width="500px">
      <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title class="text-h5">¿Está seguro de borrar este Location?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="blue darken-1" text @click="closeDelete(boards.location)">Cancel</v-btn>
          <v-btn small color="blue darken-1" text @click="deleteItemConfirm(boards.location)">OK</v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import {axiosN506} from '../api/axiosBd'
export default {
  props:{
    headers: {
      type: Object,
      required: true
    },
    boards: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      //loadings
      isLoading: false,
      loadingLocationTable: false,
      //dialog delete
      dialogDeletepLocation: false,
      //search
      locationSearch: '',
      //records
      location_records: [],
      subfamily: [],
      //form
      btnEdithVisible: false,
      valid: false,
      dialogLocation: false,
      createLocationForm: {},
      //form rules
      createFormRules: {
        required: value => !!value || 'El campo es requerido.',
        counter: value => value ? value.length <= 25 || 'Max 25 Caracteres': '',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      selectedRecord: null
    }
  },
  computed: {
  },
  methods: {
    async summitForm(method, board){
      this.isLoading = true
      let error = false
      let form = {}

      if (board == this.boards.location) {
        if (!this.$refs.createLocationForm.validate()) {
          error = true
        } else {
          form = this.createLocationForm
        }
      }



      if (!error) {
        await this.$store.dispatch('verifyToken')

        if (method == 'POST') {
          axiosN506(`${board}/add`, {
            method: "POST",
            data: form,
            headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          })
          .then(() => {
            this.isLoading = false
            this.getData(board)
            this.closeForm(board)
          }).catch((err) => {
            alert(`error: ${err.response ? err.response : err}`)
            this.isLoading = false
          })
        } else if (method == 'PUT') {
          axiosN506(`${board}/update`, {
            method: "PUT",
            data: { id: this.selectedRecord, ...form },
            headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          })
          .then(() => {
            this.isLoading = false
            this.getData(board)
            this.closeForm(board)
          }).catch((err) => {
            alert(`error: ${err.response ? err.response : err}`)
            this.isLoading = false
          })
        }
      } else {
        //construir componente o hacer prototype para mensajes de error standar en la aplicacion
        this.$alert('Favor llenar todos los campos requeridos', 'Title', {
          confirmButtonText: 'OK',
          callback: action => {
            this.isLoading = false
          }
        })
      }
    },
    editItem(item, board){
      // this.loa
      this.btnEdithVisible = true
      this.selectedRecord = item.id

      if (board == this.boards.location) {

        console.log(item)
        this.createLocationForm.location = item.location
        this.createLocationForm.info_location = item.info_location
        this.createLocationForm.adicional_info = item.adicional_info
        this.dialogLocation = true
      }

    },
    deleteItemConfirm(board) {
      this.isLoading = true

      axiosN506(`${board}/delete`, {
        method: "DELETE",
        data: { id: this.selectedRecord },
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
      })
      .then(() => {
        this.isLoading = false
        this.getData(board)
        this.closeDelete(board)
      }).catch((err) => {
        console.error(err)
        alert(`error: ${err.response ? err.response : err}`)
        this.isLoading = false
      })
    },
    openDelete(item, board) {
      if (board == this.boards.location) this.dialogDeletepLocation = true

      this.selectedRecord = item.id
    },
    openCreateForm(board) {
      if (board == this.boards.location) this.dialogLocation = true
    },
    closeForm(board) {
      if (board == this.boards.location) {
        this.dialogLocation = false
        this.$refs.createLocationForm.resetValidation()
        this.createLocationForm = {}
      }
      this.btnEdithVisible = false
      this.isLoading = false
      this.selectedRecord = null
    },
    closeDelete(board) {
      this.selectedRecord = null

      if (board == this.boards.location) this.dialogDeletepLocation = false
    },
    async getData(board){
      this.loadingTable = true
      await this.$store.dispatch('verifyToken')
      axiosN506({
        method: 'GET',
        url: `${board}/list`,
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
      })
      .then((result) => {
        if (board == this.boards.location) this.loadingLocationTable = false

        if (board == this.boards.location) this.location_records = result.data.data
      }).catch((err) => {
        console.error(err)
      });
    },
    convertToMoney(x) {
      return x
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    itemTextSubfamilyr(item){
      return `${item.name} ${item.familyId.name}`
    }
  },
  created() {
    this.getData(this.boards.location)
  }
}
</script>